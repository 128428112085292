import type { Artifact } from '@hiddendoor/shared';
import { CardFrame } from './Card';
import { Header } from './Header';

export const ArtifactCard = ({ artifact }: { artifact: Artifact }) => {
  const { storyName: name, storyLogline: logline } = artifact;

  return (
    <CardFrame className="items-center justify-between text-center p-4 pt-5">
      <Header size="tertiary">{name}</Header>
      <div>{logline}</div>
    </CardFrame>
  );
};
